import { v4 as uuidv4 } from "uuid";

const clothData = [
    {
        id: uuidv4(),
        src: process.env.PUBLIC_URL + '/patrul.jpg',
        title: 'Патруль',
        text: 'Для форменной одежды служащих силовых структур. Ткани этой группы характеризуются благородным внешним видом, характерным для высокобюджетных тканей модного ассортимента. При этом прочностные и гигиенические показатели соответствуют повышенным требованиям силовых структур.',
    },
    {
        id: uuidv4(),
        src: process.env.PUBLIC_URL + '/ripstop.jpg',
        title: 'Рип-стоп',
        text: 'Повышенная прочность на раздир, при сохранении веса и плотности ткани. Рипстоп производится с различными характеристиками текстуры, веса, водонепроницаемости, водостойкости, огнеупорности, пористости (пропускаемости воздуха или воды). Текстура варьируется от мягкой, шёлкообразной до грубой и жёсткой.',
    },
    {
        id: uuidv4(),
        src: process.env.PUBLIC_URL + '/gabardin.jpg',
        title: 'Габардин',
        text: 'Костюмы и спецодежда из синтетического габардина не мнутся, а значит сотрудник выглядит опрятно: на брюках не будет вытянутых коленок, а юбку не испортят складки. Ткань пропускает воздух и работать в офисе в такой одежде комфортно: тело дышит.',
    },
    {
        id: uuidv4(),
        src: process.env.PUBLIC_URL + '/polusher.jpg',
        title: 'Полушерсть',
        text: 'Износостойкость и долговечность, легкость, простота в уходе, по сравнению с шерстяной или чистошерстяной, гладкость: ткань приятна тактильно, меньше колется и реже вызывает раздражение на коже, хорошие теплозащитные свойства, способность сохранять форму.',
    },
    {
        id: uuidv4(),
        src: process.env.PUBLIC_URL + '/kamvol.jpg',
        title: 'Камвольная',
        text: 'Обладает следующими свойствами: несминаемость, отсутствие усадки; хорошая формоустойчивость (держит заутюженные складки и вытачки), стойкость к истиранию. Не требуют частой влажно-тепловой обработки, быстрая и простая чистка изделий.',
    },
];

export default clothData;