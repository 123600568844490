import React from 'react'

const About = () => {
    return (
        <section className='about'>
            <div className='about__container'>
                <h2 className='title about__title'>О нас</h2>
                <p className='about__text'>Наша компания с 2016 года профессионально занимается пошивом форменного обмундирования для силовых структур, а также гражданских организаций и в индивидуальном порядке. На протяжении долгого времени, мы предоставляем полный комплекс услуг от консультаций до пошива. География наших клиентов начинается с солнечного Черноморского побережья охватывая всю Россию до берегов Сахалина. Нам были вручены множественное количество грамот и благодарностей в знак надежного, оперативного и качественного подхода к выполнению заказов.</p>
                <p className='about__text'>Об «АдмиралЪ» мы знаем все! Мы осуществляем заказы на пошив военной формы как повседневной, так и парадной, а также больших размеров. <span className='about__text-blue'>Пошив формы МО ВС РФ, формы ВМФ РФ, ВКС РФ, формы МЧС, МВД, ФСБ, ФССП, ФСИН, Следственного комитета, пошив формы Прокуратуры, а также формы для генералов, адмиралов и кадетов.</span> Всё осуществляется строго по утвержденной технологии из уставных тканей. Наши специалисты обладают высоким уровнем профессионализма, а новейшее оборудование предприятия соответствует всем нормам и стандартам. Мы всегда открыты для сотрудничества и рады каждому клиенту.</p>
                <p className='about__text'>Основными задачами, над которыми мы не перестаем работать, высокое качество пошива и четкое соблюдение сроков.</p>
                <p className='about__text'>«АдмиралЪ» одно из немногих в Санкт-Петербурге швейное производство полного цикла, выполняющее любые работы по пошиву, ремонту, изготовлению военной формы любой сложности и эксклюзива․ На предприятии изготавливается форменная одежда установленного образца от рядовых до офицеров высшего состава.</p>
                <p className='about__text'>Наши преимущества: профессионализм и качество, быстрый пошив крупных заказов, низкие цены, гарантия качества.
                </p>
                <p className='about__text'>Ждём Вас, с новыми заказами в «АдмиралЪ»!</p>
            </div>
        </section>
    )
}

export default About