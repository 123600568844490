import { v4 as uuidv4 } from "uuid";

const militaryData = [
    {
        id: uuidv4(),
        title: 'Офисная форма ВМФ (длинный рукав)',
        img: process.env.PUBLIC_URL + '/vsrf-catalog.png',
        price: 'от 5500₽',
        cloth: 'Рип-стоп, патруль, габардин, рип-стоп п/ш',
        comp: 0,
    },
    {
        id: uuidv4(),
        title: 'Офисная форма ВМФ (короткий рукав)',
        img: process.env.PUBLIC_URL + '/vmf-short.png',
        price: 'от 5500₽',
        cloth: 'Рип-стоп, патруль, габардин,рип-стоп п/ш',
        comp: 0,
    },
    {
        id: uuidv4(),
        title: 'Офисная форма (длинный рукав)',
        img: process.env.PUBLIC_URL + '/ofiska-long.png',
        price: 'от 6000₽',
        cloth: 'Рип-стоп, патруль, рип-стоп п/ш, гладкокрашеная',
        comp: 0,
    },
    {
        id: uuidv4(),
        title: 'Офисная форма (короткий рукав)',
        img: process.env.PUBLIC_URL + '/ofiska-short.png',
        price: 'от 6000₽',
        cloth: 'Рип-стоп, патруль, рип-стоп п/ш, гладкокрашеная',
        comp: 0,
    },
    {
        id: uuidv4(),
        title: 'Офисная форма (длинный рукав)',
        img: process.env.PUBLIC_URL + '/ofiska-long-2.png',
        price: 'от 6000₽',
        cloth: 'Рип-стоп, патруль, рип-стоп п/ш',
        comp: 0,
    },
    {
        id: uuidv4(),
        title: 'Офисная форма (короткий рукав)',
        img: process.env.PUBLIC_URL + '/ofiska-short-2.png',
        price: 'от 6000₽',
        cloth: 'Рип-стоп, патруль, рип-стоп п/ш',
        comp: 0,
    },
    {
        id: uuidv4(),
        title: 'Офисная форма МЧС (длинный, короткий рукав, с шевронами)',
        img: process.env.PUBLIC_URL + '/mchs-catalog-long.png',
        price: 'от 6800₽',
        cloth: 'Патруль',
        comp: 0,
    },
    {
        id: uuidv4(),
        title: 'Офисная форма для кадетов и курсантов (расцветка цифра)',
        img: process.env.PUBLIC_URL + '/kadeti-catalog.png',
        price: 'от 6000₽',
        cloth: 'Рип-стоп',
        comp: 0,
    },
    {
        id: uuidv4(),
        title: 'Кадетская форма парадная',
        img: process.env.PUBLIC_URL + '/kadet-parad.png',
        price: 'от 9500₽',
        cloth: 'Полушерсть',
        comp: 0,
    },
    {
        id: uuidv4(),
        title: 'Кадетская форма',
        img: process.env.PUBLIC_URL + '/kadet-catalog.png',
        price: '6000₽',
        cloth: 'Полушерсть',
        comp: 0,
    },
    {
        id: uuidv4(),
        title: 'Офисная форма ФСИН и ФССП (длинный рукав)',
        img: process.env.PUBLIC_URL + '/fsin-long.png',
        price: 'от 6000₽',
        cloth: 'Рип-стоп, рип-стоп п/ш',
        comp: 0,
    },
    {
        id: uuidv4(),
        title: 'Офисная форма ФСИН и ФССП (короткий, рукав)',
        img: process.env.PUBLIC_URL + '/fsin-short.png',
        price: 'от 6000₽',
        cloth: 'Рип-стоп, рип-стоп п/ш',
        comp: 0,
    },
    {
        id: uuidv4(),
        title: 'Офисная форма Полиция',
        img: process.env.PUBLIC_URL + '/ofiska-police.png',
        price: '15 500₽',
        cloth: 'Гладкокрашеная п/ш',
        comp: '65% шерсть, 35% полиэстер',
    },
    {
        id: uuidv4(),
        title: 'Китель повседневный ФСО',
        img: process.env.PUBLIC_URL + '/kitel-povsed.png',
        price: '13 000₽',
        cloth: 'Полушерсть',
        comp: 0,
    },
    {
        id: uuidv4(),
        title: 'Китель Полиция',
        img: process.env.PUBLIC_URL + '/kitel-police.png',
        price: '15 000₽',
        cloth: 'Гладкокрашеная п/ш',
        comp: '65% шерсть, 35% полиэстер',
    },
    {
        id: uuidv4(),
        title: 'Костюм ВКС, ВДВ (синий) новый образец',
        img: process.env.PUBLIC_URL + '/vdv.png',
        price: 'от 19 000₽',
        cloth: 'Полушерсть',
        comp: '75% шерсть, 25% полиэстер',
    },
    {
        id: uuidv4(),
        title: 'Костюм МО (цвет морской волны) новый образец',
        img: process.env.PUBLIC_URL + '/kostum-mo.png',
        price: 'от 19 000₽',
        cloth: 'Полушерсть',
        comp: '75% шерсть, 25% полиэстер',
    },
    {
        id: uuidv4(),
        title: 'Костюм  ВМФ (белый)',
        img: process.env.PUBLIC_URL + '/vmf-white-catalog.png',
        price: 'от 25 000₽',
        cloth: 'Полушерсть',
        comp: '75% шерсть,25% полиэстер',
    },
    {
        id: uuidv4(),
        title: 'Китель ВМФ (черный)',
        img: process.env.PUBLIC_URL + '/vmf-catalog.png',
        price: 'от 19 000₽',
        cloth: 'Полушерсть',
        comp: '65% шерсть,35% полиэстер',
    },
    {
        id: uuidv4(),
        title: 'Китель Полиция стальной',
        img: process.env.PUBLIC_URL + '/police.png',
        price: 'от 15 000₽',
        cloth: 'Полушерсть',
        comp: '75% шерсть, 25% полиэстер',
    },
    {
        id: uuidv4(),
        title: 'Тужурка адмиральская',
        img: process.env.PUBLIC_URL + '/tujurka-admiral.png',
        price: '65 000₽',
        cloth: 'Полушерсть',
        comp: 0,
    },
    {
        id: uuidv4(),
        title: 'Китель генеральский',
        img: process.env.PUBLIC_URL + '/kitel-gen.png',
        price: '65 000₽',
        cloth: 'Полушерсть',
        comp: 0,
    },
    {
        id: uuidv4(),
        title: 'Китель церемониальный (РПК)',
        img: process.env.PUBLIC_URL + '/ceremonia-new.png',
        price: '75 000₽',
        cloth: 'Полушерсть',
        comp: 0,
    },
    {
        id: uuidv4(),
        title: 'Брюки парадные с кантом',
        img: process.env.PUBLIC_URL + '/bruki.png',
        price: '4000₽',
        cloth: 'Полушерсть',
        comp: 0,
    },
];

export default militaryData;